<template>
    <div class="mt-n3">
        <aswitch
                v-if="result.valueLine.source=='t_templates'"
                :fieldAttributes="{ ...fieldAttributes, 'hide-details': true }"
                :fieldAttrInput="fieldAttributes.fieldAttrInput"
                :value="localValue"
                :templateContent="result"
                @input="updateValue"
        ></aswitch>
    </div>
</template>
<script>
  import aswitch from "@/commonComponents/aswitch.vue";
  export default {
    components: { aswitch },
    props: ["result", "fieldAttributes", "value"],
    computed: {
      localValue() {
        //console.log(this.result.valueLine.source);
        return this.value;
      },
    },
    methods: {
      updateValue(value) {
        this.$emit("input",value);
      },
    },
  };
</script>